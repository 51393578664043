import React from 'react'

// Components
import Layout from '../../../components/layout.js'
import SEO from '../../../components/seo.js'

// Figures
import FigureBbotPhone from '../../../figures/phone/phone-menu'
import FigureQrCode from '../../../figures/qr-code/qr-code'

// SVGs

export default () => (
    <Layout className="project project__bbot-marketing-website">
        <SEO title="Bbot Marketing Wesbite" />
        <section className="hero">
            {/* Hero Desktop */}
            <div className="hero-desktop padding-top-14">
                <div className="container container-1600">
                    <div className="row margin-bottom-20">
                        <div className="col-md-5 position-relative order-md-1 hero-column">
                            <div className="hero-figure-container">
                                <FigureBbotPhone QrCode={<FigureQrCode />} />
                            </div>
                            <div className="hero-img-accent hero-img-accent__1" />
                            <div className="hero-img-accent hero-img-accent__2" />
                            <div className="hero-img-accent hero-img-accent__3" />
                        </div>
                        <div className="col-md-7 order-md-0">
                            <h1 className="margin-bottom-3">
                                Contactless Dining Experiences, Reimagined
                            </h1>
                            <p className="margin-bottom-4 weight-600">
                                Innovative order and pay technology <br />
                                for the food and beverage industry.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 border-bottom-light border-top-light">
                            <div className="brand-list">
                                <div className="brand-item">
                                    <img
                                        alt="logo-1"
                                        src="/svgs/customers/Layer%202.6.svg"
                                    />
                                </div>
                                <div className="brand-item">
                                    <img
                                        alt="logo-1"
                                        src="/svgs/customers/Frame-1.svg"
                                    />
                                </div>
                                <div className="brand-item">
                                    <img
                                        alt="logo-1"
                                        src="/svgs/customers/Group%20156.svg"
                                    />
                                </div>
                                <div className="brand-item">
                                    <img
                                        alt="logo-1"
                                        src="/svgs/customers/Frame.svg"
                                    />
                                </div>
                                <div className="brand-item">
                                    <img
                                        alt="logo-1"
                                        src="/svgs/customers/Group.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Hero Mobile */}
            <div className="hero-mobile">
                <div className="container padding-y-5">
                    <div className="row">
                        <div className="col-md-12 center">
                            <h2>Contactless Dining Experiences, Reimagined</h2>
                        </div>
                    </div>
                </div>
                <div className="padding-y-10 mobile-hero-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="hero-figure-container-mobile">
                                    <FigureBbotPhone
                                        QrCode={<FigureQrCode />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-orange-1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="side-by-side-divider">
                            <div className="left-content margin-bottom-3">
                                <h3 className="margin-bottom-3">
                                    I develop a painted door ecommerce
                                    experience that tested the demand for
                                    restaurants to buy Toast online.
                                </h3>
                                <p>
                                    Toast at the time did not offer the ability
                                    to purchase a Toast POS terminal online,
                                    requiring customers to call a sales
                                    representative to purchase any Toast
                                    product. Thus to reduce the cost of
                                    accquisition Toast was looking to implement
                                    a ecommerce application which would reduce
                                    the friction from buying Toast via a sales
                                    representative.
                                </p>
                            </div>
                            <div className="divider margin-x-10"></div>
                            <div className="right-content">
                                <div className="margin-bottom-3">
                                    <h4>Timeline</h4>
                                    <p>3 Week Build</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">My Role</h4>
                                    <p>Front End Developer</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="margin-bottom-1">
                                        <b>Tools</b>
                                    </h4>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">HTML</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Javascript
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Craft CMS
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">AWS</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Algolia
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h4 className="">
                                        <b>Themes</b>
                                    </h4>
                                    <p>Ecommerce | User-Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-grey-1">
            <div className="container">
                <div className="row margin-bottom-5">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">The Problem</h2>
                        <p>
                            To buy a Toast Point of Sale product a customer must
                            call a Toast respresentative or schedule a demo
                            online via the Toast website. Then they must got
                            through a series of back and forth engagements with
                            a number of sales representatives to ensure they are
                            a verified employer and to sign the appropriate
                            contracts. This process is time consuming, costly,
                            and prone to the customer losing interest and
                            possibly ending the negotation. Furthermore scaling
                            a sales organization to meet demand can be
                            inefficient. It was clear that there had to be a
                            better way to buy Toast Point of Sale however, was
                            there even a demand to purchase Toast online?
                        </p>
                    </div>
                </div>
                <div className="row margin-bottom-5">
                    <div className="col-md-8">
                        <h2 className="margin-bottom-3">The Solution</h2>
                        <p>
                            Create an experience that gives users the ability to
                            purchase Toast Point-of-Sale online without having
                            to fully invest in developing an ecommerce site
                            before understanding the demand. Customers that
                            prefer a traditional sales call can still purchase
                            Toast via a call with a Toast sales representative
                            and those that prefer not to not call can begin
                            their purchasing journey via a guided ecommerce
                            experience.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h2 className="margin-bottom-3">Goals</h2>
                        <ul className="inline-padding-none">
                            <li className="margin-bottom-2">
                                <p>Page Load Speeds are Fast</p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>Effectively Track Completion</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">My Role</h2>
                        <p>
                            I was the front-end developer responsible for
                            building the majority of the user experience. Our
                            team, to keep the application light and responsive,
                            decided to use HTML and Javascript to create our
                            template partials and couple it with Craft CMS as
                            our source of truth for our product catalog. In
                            addition to developing the front-end experience, I
                            worked alongside our sales department to effectively
                            hand off the necessary information to continue the
                            buying process once the user had completed their
                            order.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 margin-bottom-5">
                        <h2 className="">Development Process</h2>
                    </div>

                    <div className="col-md-12">
                        <h3 className="margin-bottom-3">
                            0.1 - Understanding the Scope
                        </h3>
                        <p className="margin-bottom-5">
                            Purchasing Toast is not as simple as purchasing a
                            point of sale terminal, plugging it in, and
                            accepting orders. Each restaurant layout requires a
                            different set of hardware devices to install for the
                            system to work and there is added complexity in
                            which pieces of hardware are compatiable. Thus for
                            this test we decided to offer a simplified Toast
                            bundle that was equipped for the average small to
                            medium sized restaurant.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <h3 className="margin-bottom-3">
                            0.2 - What Should the User Journey Look Like
                        </h3>
                        <p className="margin-bottom-5">
                            There were 3 main phases to purchasing Toast online.
                            a user needed to specifiy the hardware items they
                            wanted, the software subscription and modules they
                            wanted, and their installation package. We decided
                            agains a single page approach and decided to create
                            a page for each of these phases.
                        </p>

                        <div className="row number-list margin-bottom-10">
                            <div className="col-md-4 number-item margin-bottom-3">
                                <p>
                                    Select the Hardware Bundle they wanted plus
                                    any hardware add-ons they want.
                                </p>
                            </div>
                            <div className="col-md-4 number-item margin-bottom-3">
                                <p>
                                    Select whether they wanted a monthly or
                                    yearly recurring software subscription. Plus
                                    any additional software modules they want
                                    (Toast Loyalty, Toast Payroll &amp; Team
                                    Management, etc.).
                                </p>
                            </div>
                            <div className="col-md-4 number-item margin-bottom-3">
                                <p>
                                    Select the installation package they want,
                                    Remote or Premium.
                                </p>
                            </div>
                        </div>
                        <div className="row margin-bottom-3">
                            <div className="col-lg-6 offset-lg-1 order-lg-1 align-self-center position-relative margin-bottom-3">
                                <img
                                    className="border-full-2 box-shadow-brand-orange box-shadow-top-right"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/projects/toast-ecomm/Ecomm-1.jpg'
                                    }
                                    alt="Ecomm Homepage"
                                />
                            </div>
                            <div className="col-lg-5 order-lg-0 align-self-center">
                                <h3 className="margin-bottom-3">
                                    0.3 - Develop the Application
                                </h3>
                                <p>
                                    Once the user journey was determined, I
                                    developed the initial mockups provided by
                                    the design team. During the development
                                    process I worked in tandem with the design
                                    team and other our Web Team leads to adjust
                                    the user experience accordingly as design
                                    edits were made. After 3 weeks of
                                    development we settled on the final design
                                    and reviewed our final product.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
